.layer-ui__wrapper__top-right label[title="Library"] {
    display: none !important;
}

.mobile-misc-tools-container label[title="Library"] {
    display: none !important;
}


.excalidraw button.dropdown-menu-button,
.excalidraw button.help-icon {
    display: none !important;
}


.stetch-ctrl{
    width: 100%;
    height: 100%;
}

.stetch-ctrl.fullscreen {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    z-index:9999999;
}

.stetch-ctrl:not(.fullscreen) .excalidraw .App-toolbar--mobile {
    overflow-x: auto!important;
    width: 80%!important;
}

.Stack.Stack_horizontal.App-toolbar-container{
    justify-items:center;
}

/* For WebKit based browsers */
.excalidraw .App-toolbar--mobile::-webkit-scrollbar {
    width: 2px;
    height: 5px;
    background-color: #f5f5f5;
}

/* For WebKit based browsers - thumb */
.excalidraw .App-toolbar--mobile::-webkit-scrollbar-thumb {
    background-color: #aaa;
    border-radius: 1px;
    border: 1px solid #f5f5f5;
}

/* For WebKit based browsers - track */
.excalidraw .App-toolbar--mobile::-webkit-scrollbar-track {
    background-color: #f5f5f5;
    border-radius: 1px;
}


