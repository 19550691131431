.exp-container {
    position: relative;
}

    .exp-container input {
        padding-left: 23px;
    }

        .exp-container input.in-valid-char {
            border-color: orange !important;
            box-shadow: inset 0px 1px 1px rgba(0,0,0,0.075), 0px 0px 8px orange !important;
        }

    .exp-container .exp-notification {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 9px;
        left: 5px;
        cursor: help;
    }

        .exp-container .exp-notification span {
            display: none;
            color: #fff;
            width: 16px;
            height: 16px;
            line-height: 16px;
            border-radius: 10px;
            font-size: 10px;
            text-align: center;
        }


        .exp-container .exp-notification .ok {
            background-color: #2b9223;
        }

        .exp-container .exp-notification .error {
            background-color: #ba3636;
        }

    .exp-container.invalid input {
        border-color: #ba3636;
    }

    .exp-container.invalid .exp-notification .ok {
        display: none;
    }

    .exp-container.invalid .exp-notification .error {
        display: block;
    }

    .exp-container.valid .exp-notification .ok {
        display: block;
    }

    .exp-container.valid .exp-notification .error {
        display: none;
    }

    .exp-container .exp-suggestions {
        width: 100%;
        background-color: white;
        box-shadow: 0px 5px 7px 2px rgba(0,0,0,0.3);
        min-height: 50px;
        max-height: 300px;
        overflow-y: auto;
        position: absolute;
        display: none;
        z-index: 1069;
    }

        .exp-container .exp-suggestions.up {
            bottom: 36px;
        }

        .exp-container .exp-suggestions .exp-suggestion-item {
            cursor: pointer;
            width: 100%;
            padding: 3px 5px;
            min-width: 400px;
        }

            .exp-container .exp-suggestions .exp-suggestion-item.selected {
                font-weight: bold;
            }

            .exp-container .exp-suggestions .exp-suggestion-item:hover {
                background-color: #337ab7;
                color: white;
            }
